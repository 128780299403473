import React, { useState, useEffect } from 'react';
import '../styles/LocationSelector.css';

const statesByCountry = {
  USA: ["California", "Texas", "New York"],
  SINGAPORE: ["Singapore"],
  BRAZIL: ["Sao Paulo"],
  INDONESIA: ["Bali", "Jakarta", "West Java"]
};

const citiesByState = {
  California: ["San Francisco", "Los Angeles", "San Diego"],
  Texas: ["Houston", "Dallas", "Austin"],
  "New York": ["New York City", "Buffalo", "Rochester"],
  Bali: ["Denpasar", "Badung"],
  Jakarta: ["Jakarta Barat", "Jakarta Selatan"],
  "West Java": ["Bogor", "Bandung"],
  "Sao Paulo": ["Leopold couto magalhaes"],
  Singapore: ["Bedok", "Jurong West", "Tampines", "Central Region"]
};

function LocationSelector({ onLocationSelect }) {
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    setState('');
    setCity('');
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
    setCity('');
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handleFindClick = () => {
    if (country && state && city) {
      onLocationSelect({ country, state, city });
    }
  };

  useEffect(() => {
    if (country && state && city) {
      onLocationSelect({ country, state, city });
    }
  }, [country, state, city, onLocationSelect]);

  return (
    <div>
      <div className="form-group">
        <label htmlFor="country">Select Country:</label>
        <select id="country" value={country} onChange={handleCountryChange}>
          <option value="">Select a country</option>
          <option value="USA">USA</option>
          <option value="BRAZIL">Brazil</option>
          <option value="SINGAPORE">Singapore</option>
          <option value="INDONESIA">Indonesia</option>
          {/* Add more countries as needed */}
        </select>
      </div>
      {country && (
        <div className="form-group">
          <label htmlFor="state">Select State:</label>
          <select id="state" value={state} onChange={handleStateChange}>
            <option value="">Select a state</option>
            {statesByCountry[country].map((state) => (
              <option key={state} value={state}>{state}</option>
            ))}
          </select>
        </div>
      )}
      {state && (
        <div className="form-group">
          <label htmlFor="city">Select City:</label>
          <select id="city" value={city} onChange={handleCityChange}>
            <option value="">Select a city</option>
            {citiesByState[state].map((city) => (
              <option key={city} value={city}>{city}</option>
            ))}
          </select>
        </div>
      )}
      
    </div>
  );
}

export default LocationSelector;
