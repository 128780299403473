import React, { useEffect, useState } from 'react';
import { loadGoogleMapsScript } from '../utils/loadGoogleMapsScript';
import '../styles/Map.css';

const STORAGE_KEY_PREFIX = 'mapLocation_';
const EXPIRY_TIME = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const INITIAL_LOCATION = { lat: 33.921340, lng: -118.326580 };

function Map({ selectedLocation, userLocation }) {
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const [userMarker, setUserMarker] = useState(null);

  const generateStorageKey = ({ country, state, city }) => {
    return `${STORAGE_KEY_PREFIX}${country}_${state}_${city}`;
  };

  const getStoredLocation = (key) => {
    const data = localStorage.getItem(key);
    if (data) {
      const parsedData = JSON.parse(data);
      if (Date.now() - parsedData.timestamp < EXPIRY_TIME) {
        return parsedData.location;
      } else {
        localStorage.removeItem(key);
      }
    }
    return null;
  };

  const storeLocation = (key, location) => {
    const data = {
      location,
      timestamp: Date.now()
    };
    localStorage.setItem(key, JSON.stringify(data));
  };

  const getRandomLocation = (map, location, callback) => {
    const service = new window.google.maps.places.PlacesService(map);
    const request = {
      location: location,
      radius: '5000', // 5 km radius
      type: ['restaurant', 'park', 'store'] // You can change the types as needed
    };

    service.nearbySearch(request, (results, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const randomPlace = results[Math.floor(Math.random() * results.length)];
        const randomLocation = randomPlace.geometry.location;
        callback(randomLocation);
      }
    });
  };

  useEffect(() => {
    loadGoogleMapsScript(() => {
      const map = new window.google.maps.Map(document.getElementById('map'), {
        zoom: 14,
        center: INITIAL_LOCATION // Default center
      });
      setMap(map);

      const initialKey = generateStorageKey({ country: 'initial', state: 'initial', city: 'initial' });
      const storedLocation = getStoredLocation(initialKey);

      if (storedLocation) {
        // Use stored location
        const location = new window.google.maps.LatLng(storedLocation.lat, storedLocation.lng);
        map.setCenter(location);

        // Set new marker
        const newMarker = new window.google.maps.Marker({
          position: location,
          map: map
        });
        setMarker(newMarker);
      } else {
        // Generate new random location
        getRandomLocation(map, INITIAL_LOCATION, (randomLocation) => {
          map.setCenter(randomLocation);

          // Set new marker
          const newMarker = new window.google.maps.Marker({
            position: randomLocation,
            map: map
          });
          setMarker(newMarker);

          // Store the random location
          storeLocation(initialKey, { lat: randomLocation.lat(), lng: randomLocation.lng() });
        });
      }
    });
  }, []);

  useEffect(() => {
    if (map && selectedLocation) {
      const storageKey = generateStorageKey(selectedLocation);
      const storedLocation = getStoredLocation(storageKey);

      if (storedLocation) {
        // Use stored location
        const location = new window.google.maps.LatLng(storedLocation.lat, storedLocation.lng);
        map.setCenter(location);

        // Remove previous marker if exists
        if (marker) {
          marker.setMap(null);
        }

        // Set new marker
        const newMarker = new window.google.maps.Marker({
          position: location,
          map: map
        });
        setMarker(newMarker);
      } else {
        const geocoder = new window.google.maps.Geocoder();
        const { city, state, country } = selectedLocation;
        const address = `${city}, ${state}, ${country}`;

        geocoder.geocode({ address }, (results, status) => {
          if (status === 'OK') {
            const location = results[0].geometry.location;
            map.setCenter(location);

            // Remove previous marker if exists
            if (marker) {
              marker.setMap(null);
            }

            // Find random place in the city
            getRandomLocation(map, location, (randomLocation) => {
              const randomMarker = new window.google.maps.Marker({
                position: randomLocation,
                map: map,
                title: randomLocation.name
              });
              setMarker(randomMarker);
              map.setCenter(randomLocation);

              // Store the random location
              storeLocation(storageKey, { lat: randomLocation.lat(), lng: randomLocation.lng() });
            });
          } else {
            console.error(`Geocode was not successful for the following reason: ${status}`);
          }
        });
      }
    }
  }, [map, selectedLocation]);

  useEffect(() => {
    if (map && userLocation) {
      // Remove previous user marker if exists
      if (userMarker) {
        userMarker.setMap(null);
      }

      // Set new user marker
      const newUserMarker = new window.google.maps.Marker({
        position: userLocation,
        map: map,
        title: 'Your Location',
        icon: {
          url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
        }
      });
      setUserMarker(newUserMarker);
    }
  }, [map, userLocation]);

  return <div id="map" style={{ height: '500px', width: '100%' }}></div>;
}

export default Map;
