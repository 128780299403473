let isScriptLoaded = false;

export const loadGoogleMapsScript = (callback) => {
  if (isScriptLoaded) {
    callback();
    return;
  }

  const script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
  script.async = true;
  script.defer = true;
  script.onload = () => {
    isScriptLoaded = true;
    callback();
  };
  document.head.appendChild(script);
};
