import React, { useState, useEffect, useCallback, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';
import LocationSelector from './components/LocationSelector';
import Map from './components/Map';
import Login from './components/Login';
import Register from './components/Register';
import { AuthProvider, AuthContext } from './contexts/AuthContext';
import ProtectedRoute from './ProtectedRoute';
import { getAuth, signOut } from 'firebase/auth';
import './styles/App.css';

const EXPIRY_TIME = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const STORAGE_KEY = 'centralMapLocation';

function App() {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [expiryTimestamp, setExpiryTimestamp] = useState(null);
  const [remainingTime, setRemainingTime] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const storedData = localStorage.getItem(STORAGE_KEY);
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setExpiryTimestamp(parsedData.timestamp);
      if (parsedData.location) {
        setSelectedLocation(parsedData.location);
      }
    } else {
      // Initialize the central timestamp if not already set
      const now = new Date();
      const newTimestamp = now.setHours(24, 0, 0, 0); // Set timestamp to the next midnight
      const initialLocation = { lat: 33.921340, lng: -118.326580 };
      const data = {
        location: initialLocation,
        timestamp: newTimestamp
      };
      localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
      setExpiryTimestamp(newTimestamp);
      setSelectedLocation(initialLocation);
    }
  }, []);

  const handleLocationSelect = useCallback((location) => {
    setSelectedLocation(location);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (expiryTimestamp) {
        const timeLeft = expiryTimestamp - Date.now();
        if (timeLeft <= 0) {
          const now = new Date();
          const newExpiryTimestamp = now.setHours(24, 0, 0, 0); // Set timestamp to the next midnight
          setExpiryTimestamp(newExpiryTimestamp);
          localStorage.removeItem(STORAGE_KEY); // Remove stored data to trigger a new location

          // Generate a new random location
          const initialLocation = { lat: 33.921340, lng: -118.326580 };
          const data = {
            location: initialLocation,
            timestamp: newExpiryTimestamp
          };
          localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
          setSelectedLocation(initialLocation);
        } else {
          const hours = Math.floor(timeLeft / (1000 * 60 * 60));
          const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
          setRemainingTime(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [expiryTimestamp]);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate('/login');
      })
      .catch((error) => {
        console.error('Error signing out: ', error);
      });
  };

  const handleCheckLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        const userLoc = { lat: latitude, lng: longitude };
        setUserLocation(userLoc);

        console.log('User Location:', userLoc);
        console.log('Selected Location:', selectedLocation);

        const distance = getDistance(userLoc, selectedLocation);
        if (distance < 50) { // 50 meters tolerance
          alert('You are at the correct location!');
          handleSendEmail();
        } else {
          alert(`You are not at the correct location. Distance: ${distance.toFixed(2)} meters`);
        }
      }, (error) => {
        alert(`Error getting location: ${error.message}`);
      });
    } else {
      alert('Geolocation is not supported by your browser.');
    }
  };

  const getDistance = (loc1, loc2) => {
    if (!loc1 || !loc2 || !loc1.lat || !loc1.lng || !loc2.lat || !loc2.lng) {
      return NaN;
    }
    const R = 6371e3; // meters
    const φ1 = loc1.lat * Math.PI/180; // φ, λ in radians
    const φ2 = loc2.lat * Math.PI/180;
    const Δφ = (loc2.lat - loc1.lat) * Math.PI/180;
    const Δλ = (loc2.lng - loc1.lng) * Math.PI/180;

    const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ/2) * Math.sin(Δλ/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

    const d = R * c; // in meters
    return d;
  };

  const handleSendEmail = () => {
    setIsSubmitting(true);
    const templateParams = {
      to_email: 'cryptodeveloper87@gmail.com',
      from_name: currentUser.email,
      wallet_address: walletAddress,
      message: `The user is at the correct location with wallet address: ${walletAddress}`,
    };

    emailjs.send(
      'service_8fkw3gd', 
      'template_e8f41zu', 
      templateParams, 
      'xcOzKrZ8fyFO25zTb'
    ).then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      alert('Email sent successfully!');
      setIsSubmitting(false);
    }, (error) => {
      console.error('FAILED...', error);
      alert('Failed to send email.');
      setIsSubmitting(false);
    });
  };

  return (
    <div className="container">
      <div className="header">
        <h1>Maze GeoLocation</h1>
        {currentUser && <button onClick={handleLogout} className="logout-btn">Logout</button>}
      </div>
      <h2>Validator Platform</h2>
      {remainingTime && <p>New location in: {remainingTime}</p>}
      <div>
        <LocationSelector onLocationSelect={handleLocationSelect} />
      </div>
      <Map selectedLocation={selectedLocation} userLocation={userLocation} />
      <div className="form-group">
        <label htmlFor="walletAddress">Wallet Address:</label>
        <input
          type="text"
          id="walletAddress"
          value={walletAddress}
          onChange={(e) => setWalletAddress(e.target.value)}
          required
        />
      </div>
      <button onClick={handleCheckLocation} className="check-location-btn" disabled={isSubmitting}>
        {isSubmitting ? 'Checking...' : 'Check My Location'}
      </button>
    </div>
  );
}

function MainApp() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route 
            path="/" 
            element={
              <ProtectedRoute>
                <App />
              </ProtectedRoute>
            } 
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default MainApp;
